import { useEffect, useState } from "react";
import Select from "react-select";
import instance from "../../Interceptor/api_instance";
import { Dots } from "../LoadingAnimations/LoadingAnimations";
import { EditCondition } from "./EditCondition";

const usStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const mexicanStates = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Coahuila",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán",
  "Zacatecas",
];

/**
 * The RowClickModal component renders a modal with options to view documents, edit row, and close the
 * modal.
 * @returns The `RowClickModal` component is being returned. It is a functional component that renders
 * a modal with information about a row, including buttons to set documents, edit the row, and close
 * the modal.
 */
export const RowClickModal = ({
  setDocuments,
  setEditRow,
  row,
  close,
  english,
  type,
}) => {
  console.log("row", row);
  return (
    <>
      <div className="edit-modal">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {type.includes("trailer")
              ? row.trailer.trailer_number
              : row.truck.carrier_name + "-" + row.truck.truck_number}
          </h4>
          <div style={{ flexGrow: "1" }}></div>
        </div>
        <div style={{ margin: "auto" }}>
          <button className="other-btn gap" onClick={setDocuments}>
            {english("page-headers.documents")}
          </button>
          <button className="other-btn" onClick={setEditRow}>
            {english("buttons.edit-row")}
          </button>
        </div>
        <div className="btn-cont">
          <button onClick={close} className="close-btn">
            {english("buttons.close")}
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * The EditRowModal function in JavaScript handles editing and updating data for trailers and trucks,
 * including form inputs, dropdowns, and API calls.
 * @returns The `EditRowModal` component is being returned. It contains a form for editing either a
 * trailer or a truck based on the `type` prop passed to it. The form includes input fields for various
 * details such as customer, trailer/truck number, condition, origin, destination, and more. The form
 * also includes radio buttons, select dropdowns, and text inputs for user input.
 */
export const EditRowModal = ({ english, close, row, type }) => {
  const [ati, setATI] = useState(
    type.includes("trailer") ? row.trailer.trailer_type : ""
  );
  const [trailerList, setTrailerList] = useState([]);
  const [trucksList, setTrucksList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [listLoading, setListLoading] = useState(false);

  const getOriginData = (origin) => {
    if (!origin) {
      return {
        city: "",
        country: "",
        countryState: "",
      };
    }
    const [city, state, country] = origin.split(", ");
    return {
      city,
      country,
      countryState: state,
    };
  };

  const getDestinationData = (destination) => {
    if (!destination) {
      return {
        dCity: "",
        dCountry: "",
        dCountryState: "",
      };
    }
    const [dCity, dState, dCountry] = destination.split(", ");
    return {
      dCity,
      dCountry,
      dCountryState: dState,
    };
  };

  const [formDataTrailer, setFormDataTrailer] = useState(
    type.includes("trailer")
      ? {
          license_plate: row.trailer.license_plate,
          customer: row.trailer.customer,
          is_loaded: row.trailer.is_loaded,
          is_reserved: row.trailer.is_reserved,
          notes: row.notes,
          // reservation_trailer_id: row.trailer.reservation,
          // reservation_user_id: row.reservation_user_id,
          is_seal: row.trailer.is_seal,
          seal_number: row.trailer.seal_number,
          trailer_condition: row.trailer.trailer_condition,
          trailer_condition_notes: row.trailer.trailer_condition_notes,
          trailer_id: row.trailer.id,
          trailer_number: row.trailer.trailer_number,
          origin: row.trailer.origin,
          ...getOriginData(row.trailer.origin),
          destination: row.trailer.destination,
          ...getDestinationData(row.trailer.destination),
          prev_trailer_number: row.trailer.trailer_number,
          trailerType: null,
        }
      : ""
  );

  const [formDataTruck, setFormDataTruck] = useState(
    type.includes("truck")
      ? {
          carrier_name: row.truck.carrier_name,
          check_in_dt: row.check_in_dt,
          first_name: row.visitor.first_name,
          last_name: row.visitor.last_name,
          notes: row.notes,
          truck_id: row.truck.id,
          truck_number: row.truck.truck_number,
          truck_condition: row.truck.truck_condition,
          truck_condition_notes: row.truck.truck_condition_notes,
          origin: row.truck.origin,
          ...getOriginData(row.truck.origin),
          prev_truck_number: row.truck.truck_number,
          prev_carrier_name: row.truck.carrier_name,
          destination: row.truck.destination,
          ...getDestinationData(row.truck.destination),
        }
      : ""
  );

  async function fetchData() {
    if (type.includes("trailer")) {
      setListLoading(true);
      try {
        const trailers = await instance.get("assets/out-trailers/");
        if (trailers.status === 200) {
          setTrailerList(trailers.data);
          setListLoading(false);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    }
    if (type.includes("truck")) {
      setListLoading(true);
      try {
        const trucks = await instance.get("assets/get-trucks-out");
        if (trucks.status === 200) {
          setTrucksList(trucks.data);
          setListLoading(false);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, [formDataTrailer.carrier_name, type]);

  const postEditChanges = async (e) => {
    e.preventDefault();
    if (type.includes("trailer")) {
      formDataTrailer.trailerType = ati;
      formDataTrailer.origin = `${formDataTrailer.city}, ${formDataTrailer.countryState}, ${formDataTrailer.country}`;
      formDataTrailer.destination = `${formDataTrailer.dCity}, ${formDataTrailer.dCountryState}, ${formDataTrailer.dCountry}`;
      formDataTrailer.is_seal = formDataTrailer.is_loaded
        ? formDataTrailer.is_seal
        : false;
      formDataTrailer.seal_number = formDataTrailer.is_seal
        ? formDataTrailer.seal_number
        : null;
      try {
        setIsLoading(true);
        const response = await instance.post(
          "assets/edit-trailer",
          formDataTrailer
        );

        window.location.reload();
        // fetchData()
        // close()
      } catch (error) {
        setErrorMessage(error);
      }
    } else {
      formDataTruck.origin = `${formDataTruck.city}, ${formDataTruck.countryState}, ${formDataTruck.country}`;
      formDataTruck.destination = `${formDataTruck.dCity}, ${formDataTruck.dCountryState}, ${formDataTruck.dCountry}`;
      formDataTruck.truck_number = formDataTruck.truck_number.toString();

      try {
        setIsLoading(true);
        const response = await instance.post(
          "assets/edit-truck",
          formDataTruck
        );
        window.location.reload();
        // fetchData()
        // close()
      } catch (error) {
        setErrorMessage(error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert string "false" to boolean false
    const newValue =
      value === "false" ? false : value === "true" ? true : value;

    if (type.includes("trailer")) {
      setFormDataTrailer((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
      console.log("submitted form", formDataTrailer);
    } else {
      setFormDataTruck((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  // Function to handle changes in the react-select dropdown
  const handleTrailerNumberChange = (selectedOption) => {
    const value = selectedOption
      ? selectedOption.value
      : row.trailer.trailer_number;
    setFormDataTrailer((prevState) => ({
      ...prevState,
      trailer_number: value,
    }));
  };

  // Function to handle changes in the react-select dropdown
  const handleTruckNumberChange = (selectedOption) => {
    const value = selectedOption
      ? selectedOption.value
      : row.truck.truck_number;
    setFormDataTruck((prevState) => ({
      ...prevState,
      truck_number: value,
    }));
  };

  const trailerCheckoutOptions = trailerList?.map((trailer) => ({
    value: `${trailer.unit}-${trailer.unit_number}`,
    label: `${trailer.unit}-${trailer.unit_number} ${
      trailer.location ? trailer.location.loc_name : ""
    }`,
    isDisabled: trailer.location ? true : false,
  }));

  const atiTruckCheckoutOptions = trucksList.ati?.map((truck) => ({
    value: listLoading ? "" : truck.name,
    label: listLoading
      ? ""
      : `${truck.name} ${truck.location ? truck.location.loc_name : ""}`,
    isDisabled: truck.location ? true : false,
  }));

  const jfgTruckCheckoutOptions = trucksList.jfg?.map((truck) => ({
    value: listLoading ? "" : truck.name,
    label: listLoading
      ? ""
      : `${truck.name} ${truck.location ? truck.location.loc_name : ""}`,
    isDisabled: truck.location ? true : false,
  }));

  // Styles for the searchable select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "4px 16px",
      border: "none",
      width: "100%", // Make sure control is full width
      borderRadius: "16px",
      background: "var(--neutral-light-purple, #f4f4ff)",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      color: "#5656b9",
      minHeight: "auto", // Override to handle padding
    }),

    container: (provided) => ({
      ...provided,
      width: "100%", // Ensure the dropdown menu is also full width
      color: "#5656b9",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#5656b9",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "gray" : provided.color,
      borderBottom: "0.5px solid gray",
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#F4F4FF",
      boxShadow: "0px 0px 7px 0px rgba(207, 190, 255, 0.22)",
      borderRadius: "24px",
      padding: "16px",
      marginTop: "10px",
    }),
  };
  console.log(type);
  return (
    <>
      <div className="edit-modal ">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {english("forms.type.edit-entry")}
          </h4>
          <p className="gray">{row.trailer_number}</p>
          <form onSubmit={(e) => postEditChanges(e)}>
            {/* Customer input */}
            {type.includes("trailer") && (
              <div className="form-group">
                <label htmlFor="" className="input-label">
                  {english("yard-tables.search-filters.dropdowns.customer")}
                </label>
                <div className="input-cont">
                  <input
                    type="text"
                    value={formDataTrailer.customer}
                    className="text-input"
                    name="customer"
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {/* Is the trailer ATI? */}
            {type.includes("trailer") ? (
              <div className="form-group">
                <label className="input-label">
                  {english("forms.type.vehicle.input-headers.ati-bool")}{" "}
                </label>
                <div className="input-cont">
                  <fieldset className="fieldsets">
                    <div className="radio-options">
                      <input
                        type="radio"
                        name="group2"
                        className="radio-input"
                        checked={ati}
                        onChange={(e) => setATI(true)}
                        required
                      />
                      <label>{english("general.yes")}</label>
                    </div>
                    <div className="radio-options">
                      <input
                        type="radio"
                        id="no1"
                        name="group2"
                        className="radio-input"
                        checked={ati === false}
                        onChange={() => setATI(false)}
                      />
                      <label>No</label>
                    </div>
                  </fieldset>
                </div>
              </div>
            ) : (
              // Carrier name truck
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.carrier")}
                </label>
                <div className="input-cont">
                  <select
                    value={formDataTruck.carrier_name}
                    onChange={handleChange}
                    id="carrier"
                    name={"carrier_name"}
                    required
                  >
                    <option
                      value={english("forms.type.vehicle.placeholders.other")}
                    >
                      {english("forms.type.vehicle.placeholders.other")}
                    </option>
                    <option value="JFG">JFG</option>
                    <option value="ATI">ATI</option>
                  </select>
                  {formDataTruck.carrier_name !== "ATI" &&
                    formDataTruck.carrier_name !== "JFG" && (
                      <input
                        id="other-carrier"
                        type="text"
                        value={formDataTruck.carrier_name}
                        name="carrier_name"
                        className="text-input"
                        placeholder={english(
                          "forms.type.vehicle.placeholders.other-carrier"
                        )}
                        onChange={handleChange}
                        required
                      />
                    )}
                </div>
              </div>
            )}
            {/* Trailer number select internal */}
            {type.includes("trailer")
              ? ati && (
                  <div className="form-group">
                    <label className="input-label">
                      {english(
                        "forms.type.vehicle.placeholders.select-trailer"
                      )}{" "}
                    </label>
                    <div className="input-cont">
                      <Select
                        onChange={handleTrailerNumberChange}
                        options={trailerCheckoutOptions}
                        placeholder={row.trailer.trailer_number}
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                      />
                    </div>
                  </div>
                )
              : (formDataTruck.carrier_name === "ATI" ||
                  formDataTruck.carrier_name === "JFG") && (
                  // truck number select internal
                  <div className="form-group">
                    <label className="input-label">
                      {english("forms.type.vehicle.placeholders.select-truck")}{" "}
                    </label>
                    <div className="input-cont">
                      <Select
                        onChange={handleTruckNumberChange}
                        options={
                          formDataTruck.carrier_name === "ATI"
                            ? atiTruckCheckoutOptions
                            : jfgTruckCheckoutOptions
                        }
                        placeholder={row.truck.truck_number}
                        isClearable={true}
                        isSearchable={true}
                        styles={customStyles}
                        unstyled={true}
                      />
                    </div>
                  </div>
                )}

            {/* Trailer number text input external */}
            {type.includes("trailer")
              ? !ati && (
                  <div className="form-group">
                    <label htmlFor="" className="input-label">
                      Enter trailer number
                    </label>
                    <div className="input-cont">
                      <input
                        type="text"
                        value={formDataTrailer.trailer_number}
                        className="text-input"
                        name="trailer_number"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )
              : formDataTruck.carrier_name !== "ATI" &&
                formDataTruck.carrier_name !== "JFG" && (
                  // truck number text input external
                  <div className="form-group">
                    <label htmlFor="" className="input-label">
                      Enter truck number
                    </label>
                    <div className="input-cont">
                      <input
                        type="text"
                        value={formDataTruck.truck_number}
                        className="text-input"
                        name="truck_number"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

            {type.includes("trailer") && (
              // is trailer loaded select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.search-filters.dropdowns.contents")}{" "}
                </label>
                <div className="input-cont">
                  <select
                    id="contentSelect"
                    onChange={handleChange}
                    value={formDataTrailer.is_loaded}
                    name="is_loaded"
                  >
                    <option value="" disabled>
                      {english(
                        "forms.type.vehicle.placeholders.select-content"
                      )}
                    </option>
                    <option
                      value={true}
                      selected={formDataTrailer.is_loaded === true}
                    >
                      {english("forms.type.vehicle.placeholders.loaded")}
                    </option>
                    <option
                      value={false}
                      selected={formDataTrailer.is_loaded === false}
                    >
                      {english("forms.type.vehicle.placeholders.empty")}
                    </option>
                  </select>
                </div>
              </div>
            )}

            {formDataTrailer.is_loaded && (
              // is there a trailer seal input
              <div className="form-group">
                <label className="input-label">
                  {english("forms.type.vehicle.placeholders.seal")}{" "}
                </label>
                <div className="input-cont">
                  <select
                    id="sealSelect"
                    onChange={handleChange}
                    value={formDataTrailer.is_seal}
                    name="is_seal"
                    required
                  >
                    <option value="" disabled selected>
                      {english("forms.type.vehicle.placeholders.select-status")}
                    </option>
                    <option value={true}>
                      {english("forms.type.vehicle.placeholders.seal")}
                    </option>
                    <option value={false}>
                      {english("forms.type.vehicle.placeholders.noseal")}
                    </option>
                  </select>
                </div>
              </div>
            )}

            {formDataTrailer.is_seal && formDataTrailer.is_loaded && (
              // Trailer seal number input
              <div className="form-group">
                <label className="input-label ">
                  {english("forms.type.vehicle.input-headers.sealNum")}{" "}
                </label>
                <div className="input-cont">
                  <input
                    id="sealNum"
                    onChange={handleChange}
                    value={formDataTrailer.seal_number}
                    name="seal_number"
                    className="text-input"
                    placeholder={english("forms.type.vehicle.placeholders.num")}
                    required
                  />
                </div>
              </div>
            )}

            {type.includes("trailer") ? (
              // Trailer condition select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.condition")}
                </label>
                <div className="input-cont">
                  <div>
                    <select
                      id="conditionSelect"
                      onChange={handleChange}
                      value={formDataTrailer.trailer_condition}
                      name="trailer_condition"
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.header"
                        )}
                      </option>
                      <option value="Good">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </option>
                      <option value="Okay">Okay</option>
                      <option value="Poor">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </option>
                      <option value="Critical">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.critical"
                        )}
                      </option>
                      <option value="Under Repair">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.under-repair"
                        )}
                      </option>
                    </select>
                    <textarea
                      type="text"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.notes"
                      )}
                      value={formDataTrailer.trailer_condition_notes}
                      maxLength={100}
                      name="trailer_condition_notes"
                      onChange={handleChange}
                      required={
                        formDataTrailer.trailer_condition === "Poor" ||
                        formDataTrailer.trailer_condition === "Critical" ||
                        formDataTrailer.trailer_condition === "Under Repair"
                          ? true
                          : false
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            ) : (
              // truck condition select
              <div className="form-group">
                <label className="input-label">
                  {english("yard-tables.table-columns.condition")}
                </label>
                <div className="input-cont">
                  <div>
                    <select
                      id="conditionSelect"
                      onChange={handleChange}
                      value={formDataTruck.truck_condition}
                      name="truck_condition"
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.header"
                        )}
                      </option>
                      <option value="Good">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.good"
                        )}
                      </option>
                      <option value="Okay">Okay</option>
                      <option value="Poor">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.poor"
                        )}
                      </option>
                      <option value="Critical">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.critical"
                        )}
                      </option>
                      <option value="Under Repair">
                        {english(
                          "forms.type.vehicle.placeholders.select-condition.under-repair"
                        )}
                      </option>
                    </select>
                    <textarea
                      type="text"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.notes"
                      )}
                      value={formDataTruck.truck_condition_notes}
                      name="truck_condition_notes"
                      onChange={handleChange}
                      required={
                        formDataTruck.truck_condition === "Poor" ||
                        formDataTruck.truck_condition === "Critical" ||
                        formDataTrailer.truck_condition === "Under Repair"
                          ? true
                          : false
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            )}

            {type.includes("trailer") ? (
              // Trailer origin input
              <>
                <div className="form-group">
                  <label className="input-label">
                    {english("forms.type.vehicle.input-headers.origin")}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="country"
                      onChange={handleChange}
                      value={formDataTrailer.country}
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="countryState"
                      value={formDataTrailer.countryState}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTrailer.country === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="city"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTrailer.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Trailer destination input */}
                <div className="form-group">
                  <label className="input-label">
                    {english(
                      "yard-tables.search-filters.dropdowns.destination"
                    )}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="dCountry"
                      onChange={handleChange}
                      value={formDataTrailer.dCountry}
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="dCountryState"
                      value={formDataTrailer.dCountryState}
                      onChange={handleChange}
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTrailer.dCountry === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="dCity"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTrailer.dCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Truck origin */}
                <div className="form-group">
                  <label className="input-label">
                    {english("forms.type.vehicle.input-headers.origin")}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="country"
                      onChange={handleChange}
                      value={formDataTruck.country}
                      required
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="countryState"
                      value={formDataTruck.countryState}
                      onChange={handleChange}
                      required
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTruck.country === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="city"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTruck.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {/* Truck destination */}
                <div className="form-group">
                  <label className="input-label">
                    {english(
                      "yard-tables.search-filters.dropdowns.destination"
                    )}
                  </label>
                  <div className="input-cont">
                    <select
                      id="country"
                      name="dCountry"
                      onChange={handleChange}
                      value={formDataTruck.dCountry}
                    >
                      <option value="" selected disabled>
                        {english(
                          "forms.type.vehicle.placeholders.destination.country"
                        )}
                      </option>
                      <option value="USA">
                        {english("forms.type.vehicle.placeholders.usa")}
                      </option>
                      <option value="Mexico">Mexico</option>
                    </select>
                    <select
                      id="state"
                      name="dCountryState"
                      value={formDataTruck.dCountryState}
                      onChange={handleChange}
                    >
                      <option value="">
                        {english(
                          "forms.type.vehicle.placeholders.destination.state"
                        )}
                      </option>
                      {formDataTruck.dCountry === "USA"
                        ? usStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : mexicanStates.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="input-label"></label>
                  <div className="input-cont">
                    <input
                      id="city"
                      type="text"
                      name="dCity"
                      className="text-input"
                      placeholder={english(
                        "forms.type.vehicle.placeholders.destination.City"
                      )}
                      value={formDataTruck.dCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="btn-cont">
              {errorMessage && <p className="error">{errorMessage}</p>}
              {!isLoading ? (
                <button type="submit" className="other-btn">
                  {english("buttons.update")}
                </button>
              ) : (
                <Dots />
              )}
              <button onClick={close} className="close-btn">
                {english("buttons.close")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
