import "../../CSS/navbar.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../Media/LanguageContext";
import { ProfilePhoto } from "../../Media/PlaceHolders";
import { useAuth } from "../../Auth/AuthProvider";
import { useWindowWidth } from "../Utility/useWindowWidth";
import {
  HamBurger,
  OverviewIcon,
  InventoryIcon,
  CheckInIcon,
  CheckOutIcon,
  LogoutIcon,
} from "../../Media/Icons";
import BlankSelect from "../Inputs/BlankSelect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const Navigation = () => {
  const [isAuth, setIsAuth] = useState(false);
  const { user, pfp } = useAuth();
  const width = useWindowWidth();
  const isMobile = width <= 500;
  const [isNav, setIsNav] = useState(false);
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(Array(3).fill(false));

  useEffect(() => {
    try {
      if (user !== null) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    } catch (e) {
      window.alert("Error occurred loading: " + e);
    }
  }, [user]);

  const MobileNav = () => {
    const isRouteActive = (route) => location.pathname === route;
    const toggleOpen = (index) => {
      const updatedOpenStates = isOpen.map((state, i) =>
        i === index ? !state : false
      );
      setIsOpen(updatedOpenStates);
    };
    const { t } = useTranslation();
    const handleOnSelect = () => {
      setIsNav(false);
    };

    const navRoutes = {
      Guard: {
        role: "Guard",
        url: [
          "/guard-overview",
          "/inventory-view",
          "/check-in-trailer",
          "/check-out",
          ...(user
            ? user.groups.some((group) => group.name === "Guard Supervisor")
              ? ["/backlog"]
              : []
            : []),
        ],
        text: [
          t("page-headers.overview"),
          t("page-headers.inventory"),
          t("page-headers.check-in"),
          t("page-headers.check-out"),
          ...(user
            ? user.groups.some((group) => group.name === "Guard Supervisor")
              ? [t("page-headers.backlog")]
              : []
            : []),
        ],
        icon: [
          <OverviewIcon />,
          <InventoryIcon />,
          <CheckInIcon />,
          <CheckOutIcon />,
          ...(user
            ? user.groups.some((group) => group.name === "Guard Supervisor")
              ? [<OverviewIcon />]
              : []
            : []),
        ],
      },
      Dispatch: {
        role: "Dispatch",
        url: [
          "/dispatch-overview",
          "/inventory-view",
          "/reservations",
          "/backlog",
        ],
        text: [
          t("page-headers.overview"),
          t("page-headers.inventory"),
          t("page-headers.reservations"),
          t("page-headers.backlog"),
        ],
        icon: [
          <OverviewIcon />,
          <InventoryIcon />,
          <CheckInIcon />,
          <OverviewIcon />,
        ],
      },
      Safety: {
        role: "Safety",
        url: [
          "/guard-overview",
          "/new-driver",
          "/driving-team",
          "/check-in-trailer",
          "/check-out",
          "/backlog",
        ],
        text: [
          t("page-headers.overview"),
          t("page-headers.newDriver"),
          t("page-headers.driving-team"),
          t("page-headers.check-in"),
          t("page-headers.check-out"),
          t("page-headers.backlog"),
        ],
        icon: [
          <InventoryIcon />,
          <OverviewIcon />,
          <InventoryIcon />,
          <CheckInIcon />,
          <CheckOutIcon />,
          <InventoryIcon />,
        ],
      },
    };
    return (
      <>
        <div
          style={{
            zIndex: "5",
            backgroundColor: "#295FFC",
            position: "fixed",
            marginTop: "88px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ul className="menu">
            {user.role === "Admin"
              ? Object.values(navRoutes).map((item, index) => (
                  <li key={index} className="menu-item text-dark">
                    <BlankSelect
                      onSelect={handleOnSelect}
                      link={navRoutes[item.role].url}
                      key={index}
                      text={item.role}
                      options={item.text}
                      isOpen={isOpen[index]}
                      onToggleOpen={() => toggleOpen(index)}
                    />
                  </li>
                ))
              : navRoutes[user.role]?.text.map((item, index) => (
                  <>
                    <li
                      className={`menu-item ${
                        isRouteActive(navRoutes[user.role].url[index])
                          ? "active"
                          : "inactive"
                      }`}
                      key={index}
                    >
                      <div>
                        <Link to={navRoutes[user.role].url[index]}>
                          {React.cloneElement(
                            navRoutes[user.role].icon[index],
                            {
                              color: isRouteActive(
                                navRoutes[user.role].url[index]
                              )
                                ? "white"
                                : user.theme === "System"
                                ? "#CFBEFF"
                                : "#2EBCEE",
                            }
                          )}
                          <span
                            className={
                              isRouteActive(navRoutes[user.role].url[index])
                                ? "active-span"
                                : user.theme === "System"
                                ? "s-inactive"
                                : "a-inactive"
                            }
                          >
                            {item}
                          </span>
                        </Link>
                      </div>
                    </li>
                  </>
                ))}
            <li
              className={`menu-item ${
                isRouteActive("/logout") ? "active" : "inactive"
              }`}
            >
              <Link to="/logout">
                <LogoutIcon
                  color={
                    isRouteActive("/logout")
                      ? "white"
                      : user.theme === "System"
                      ? "#CFBEFF"
                      : "#2EBCEE"
                  }
                />
                <span
                  className={
                    isRouteActive("/logout")
                      ? "active-span"
                      : user.theme === "System"
                      ? "s-inactive"
                      : "a-inactive"
                  }
                >
                  {t("page-headers.logout")}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <div>
      {isAuth && user ? (
        <div className="navbarCont">
          {isMobile && (
            <div onClick={() => setIsNav(!isNav)} style={{ color: "#5656B9" }}>
              <HamBurger />
            </div>
          )}
          {user.groups.some((group) =>
            ["Safety", "Guard"].includes(group.name)
          ) ? (
            <h2 className="global-h2 light-primary-lilac">
              {user.location.loc_name}
            </h2>
          ) : (
            <div></div>
          )}
          <div className="profile-content">
            <Link onClick={() => {
  setIsOpen(Array(3).fill(false));
  setIsNav(false);
}}
 to="/settings">
              {pfp && pfp !== "null" ? (
                <img src={pfp} alt='profile-pic' className="profile_pic" />
              ) : (
                <ProfilePhoto />
              )}
              <div className="profile-id">
                <h4 className="global-h4 light-black">
                  {user.first_name} {user.last_name}
                </h4>
                <h5 className="global-h5 gray">{user.email}</h5>
              </div>
            </Link>
          </div>
        </div>
      ) : null}
      {isNav && <MobileNav />}
    </div>
  );
};
