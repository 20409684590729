export const sortTrailers = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => a.trailer.trailer_number.localeCompare(b.trailer.trailer_number) * (order ? 1 : -1));
    setTrailers(newList);
    setOrder(order ? false : true);
}

export const sortCustomers = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => {
        const customerA = a.trailer.customer ? a.trailer.customer : "None";
        const customerB = b.trailer.customer ? b.trailer.customer : "None";
        return customerA.localeCompare(customerB) * (order ? 1 : -1)
    });
    setTrailers(newList);
    setOrder(order ? false : true);
}

export const sortReservations = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => {
        const first = `${a.reservation.user.first_name} ${a.reservation.user.last_name}`;
        const second = `${b.reservation.user.first_name} ${b.reservation.user.last_name}`;
        return first.localeCompare(second) * (order ? 1 : -1)
    }
    )
    setTrailers(newList);
    setOrder(order ? false : true)
}

export const sortContents = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a,b) => {
        if (order) {
            return a.is_loaded - b.is_loaded; //true (1) comes after false (0)
        }
        else {
            return b.is_loaded - a.is_loaded; //false (0) comes after true (1)
        }
    });
    setTrailers(newList);
    setOrder(!order);
}

export const sortSeal = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => {
        const sealA = a.seal_number ? a.seal_number : "";
        const sealB = b.seal_number ? b.seal_number : "";
        return sealA.localeCompare(sealB) * (order ? 1 : -1)
    });
    setTrailers(newList);
    setOrder(!order);
}

export const sortCondition = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => a.trailer_condition.localeCompare(b.trailer_condition) * (order ? 1 : -1))
    setTrailers(newList);
    setOrder(!order);
}
export const sortArrivalTimes = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a, b) => {
        const dateA = new Date(a.check_in_dt);
        const dateB = new Date(b.check_in_dt);
        return (dateA - dateB) * (order ? -1 : 1);
    })
    setTrailers(newList);
    setOrder(order ? false : true);
}

export const sortLocation = (assets, setAssets, order, setOrder) => {
    const newList = [...assets];
    newList.sort((a, b) => a.loc_name.localeCompare(b.loc_name) * (order ? 1 : -1))
    setAssets(newList);
    setOrder(!order);
}

export const sortTruck = (trucks, setTrucks, order, setOrder) => {
    const newList = [...trucks];
    newList.sort((a,b) => a.truck_number.localeCompare(b.truck_number) * (order ? 1 : -1));
    setTrucks(newList);
    setOrder(order ? false : true);
}

export const sortTruckList = (trucks, setTrucks) => {
    const newList = [...trucks];
    newList.sort((a,b) => {
        const truckA = a.carrier_name + "-" + a.truck_number;
        const truckB = b.carrier_name + "-" + b.truck_number;
        return truckA.localeCompare(truckB);
    })
    setTrucks(newList);
}

export function sortByTruckNumber(trucks) {
    trucks.sort((a,b) => {
        const truckA = a.truck_number.toLowerCase();
        const truckB = b.truck_number.toLowerCase();

        if (truckA < truckB) {
            return -1;
        } else if (truckA > truckB) {
            return 1;
        } else {
            return 0;
        }
    });

    return trucks;
}

export const sortCarrier = (trucks, setTrucks, order, setOrder) => {
    const newList = [...trucks];
    newList.sort((a,b) => a.carrier_name.localeCompare(b.carrier_name) * (order ? 1 : -1));
    setTrucks(newList);
    setOrder(order ? false : true);
}

export const sortDestination = (trailers, setTrailers, order, setOrder) => {
    const newList = [...trailers];
    newList.sort((a,b) => a.destination.localeCompare(b.destination) * (order ? 1 : -1));
    setTrailers(newList);
    setOrder(order ? false : true);
}

export const isGuard = () => {
    if (localStorage.getItem("user") === null) {
        return false;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (['Admin', 'Guard'].includes(user.role)) {
        return true;
    }
    return false;
}

export const isSafety = (user) => {
    if (localStorage.getItem("user") === null) {
        return false;
    }
    if (['Admin', 'Safety'].includes(user.role)) {
        return true;
    }
    return false;
}

export const isMechanic = (user) => {
    if (localStorage.getItem("user") === null) {
        return false;
    }
    if (["Mechanic"].includes(user.role)) {
        return true;
    }
    return false;
}

export const isDispatch = (user) => {
    if (user === null) {
        return false;
    }
    
    if (user.groups.some(group => ["Admin", "Dispatch"].includes(group.name))) {
        return true;
    }
    return false;
}

export const isIT = (user) => {
    if (user.role === 'IT') {
        return true;
    }
    return false;
}