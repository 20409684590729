import { useState, useEffect } from "react";
import instance from "../../Interceptor/api_instance";
import { Dots } from "../LoadingAnimations/LoadingAnimations";

export const EditCondition = ({ english, close, row, type }) => {
  const [vehicleCondition, setVehicleCondition] = useState(
    type === "trailer"
      ? row.trailer.trailer_condition
      : row.truck.truck_condition
  );
  const [vehicleConditionNotes, setVehicleConditionNotes] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postConditionChange = async (e) => {
    e.preventDefault();
    const requestData = {
      vehicle_id: type === "trailer" ? row.trailer.id : row.truck.id,
      employee_pin: pinNumber,
      condition: vehicleCondition,
      condition_notes: vehicleConditionNotes,
    };
    try {
      setIsLoading(true);
      const url = `safety/${
        type === "trailer"
          ? "mechanic-edit-trailer-condition"
          : "mechanic-edit-truck-condition"
      }`;
      const response = await instance.patch(url, requestData);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      setErrorMessage(error);
      setIsLoading(false);
    } 
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and restrict length to 6 digits
    if (/^\d*$/.test(value) && value.length <= 6) {
      setPinNumber(value);
    }
  };
  useEffect(() => {}, []);

  return (
    <>
      <div className="edit-modal ">
        <div>
          <h4 data-testid={"documents"} className="global-h2">
            {english("forms.type.edit-entry")}
          </h4>
          <p className="gray">{row.trailer_number}</p>
          <form onSubmit={(e) => postConditionChange(e)}>
            <div className="form-group">
              <label className="input-label">
                {english("yard-tables.table-columns.condition")}
              </label>
              <div className="input-cont">
                <div>
                  <select
                    id="conditionSelect"
                    onChange={(e) => setVehicleCondition(e.target.value)}
                    value={vehicleCondition}
                    name="trailer_condition"
                    required
                  >
                    <option value="" selected disabled>
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.header"
                      )}
                    </option>
                    <option value="Good">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.good"
                      )}
                    </option>
                    <option value="Okay">Okay</option>
                    <option value="Poor">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.poor"
                      )}
                    </option>
                    <option value="Critical">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.critical"
                      )}
                    </option>
                    <option value="Under Repair">
                      {english(
                        "forms.type.vehicle.placeholders.select-condition.under-repair"
                      )}
                    </option>
                  </select>
                  <textarea
                    type="text"
                    className="text-input"
                    placeholder={row.trailer ? row.trailer.trailer_condition_notes : row.truck.truck_condition_notes}
                    value={vehicleConditionNotes}
                    maxLength={100}
                    name="trailer_condition_notes"
                    onChange={(e) => setVehicleConditionNotes(e.target.value)}
                    required
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label className="input-label">Pin #</label>
              <div className="input-cont">
                <input
                  type="password"
                  value={pinNumber}
                  onChange={handlePinChange}
                  placeholder={english("forms.type.vehicle.placeholders.enter-pin")}
                  maxLength="6"
                  className="text-input"
                  required
                />
              </div>
            </div>
            <div className="btn-cont">
              {errorMessage && <p className="error">{errorMessage}</p>}
              {!isLoading ? (
                <button type="submit" className="other-btn">
                  {english("buttons.update")}
                </button>
              ) : (
                <Dots />
              )}
              <button onClick={close} className="close-btn">
                {english("buttons.close")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
