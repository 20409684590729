import "../../CSS/guardOverview.css";
import "../../CSS/global.css";
import { useEffect, useState } from "react";
import { DispatchGraphics } from "../DispatchGraphics";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import UniversalTable from "../Tables/UniversalTable";
import instance from "../../Interceptor/api_instance";
import {
  dispatchTrailerHeaders,
  dispatchTruckHeaders,
} from "../Tables/TableHeaders";

export const DispatchOverview = () => {
  const [user, setUser] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [trailerData, setTrailerData] = useState([]);
  const [truckData, setTruckData] = useState([]);
  const [table, setTable] = useState("trailers-table");
  const [isTrailerLoading, setIsTrailerLoading] = useState(false);
  const [isTruckLoading, setIsTruckLoading] = useState(false);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUser(userData);
    fetchTrailerData();
    fetchTruckData();
  }, []);

  const fetchTrailerData = async () => {
    setIsTrailerLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trailers");
      setTrailerData(driverData.data);
      setIsTrailerLoading(false);
    } catch (error) {
      setError(error);
    }
  };
  const fetchTruckData = async () => {
    setIsTruckLoading(true);
    try {
      let driverData = null;
      driverData = await instance.get("assets/view-trucks");
      setTruckData(driverData.data);
      setIsTruckLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const active = async (e) => {
    const previous = document.getElementsByClassName("selected");
    for (var i = 0; i < previous.length; ++i) {
      previous[i].className = "tab";
    }
    e.target.className = "selected";
    setTable(e.target.id);
  };
  return (
    <div className="global-cont">
      <Helmet>
        <title>Forward | Overview</title>
      </Helmet>
      <div className="page-overview">
        <div className="cont-wrapper">
          <DispatchGraphics
            user={user}
            english={t}
            trailers={trailerData}
            trucks={truckData}
            isTrailerLoading={isTrailerLoading}
            isTruckLoading={isTruckLoading}
          />
          {/* <DispatchInventory english={t} /> */}

          <div className="inventory-header">
            <h2 className="light-black global-h2">
              {t("page-headers.inventory")}
            </h2>
          </div>
          <ul className="yard-tabs">
            <li className="selected" onClick={active} id="trailers-table">
              {t("yard-tables.tabs.trailers")} ({trailerData.length})
            </li>
            <li className="tab" onClick={active} id="trucks-table">
              {t("yard-tables.tabs.trucks")} ({truckData.length})
            </li>
          </ul>
          {table === "trailers-table" && (
            <UniversalTable
              english={t}
              data={trailerData}
              headers={dispatchTrailerHeaders(user)}
              filters={["Location", "Customer", "Contents", "Reservation"]}
              type="trailer"
              error={error}
              pageLoading={isTrailerLoading}
              user={user}
            />
          )}
          {table === "trucks-table" && (
            <UniversalTable
              english={t}
              data={truckData}
              headers={dispatchTruckHeaders(user)}
              filters={["Location", "Driver"]}
              type="truck"
              error={error}
              pageLoading={isTruckLoading}
              user={user}
            />
          )}
        </div>
      </div>
    </div>
  );
};
